<template>
  <div>

    <div class="container mb-5">
      <div class="row d-flex justify-content-between">
        <div class="col-sm-3 pt-5">
          <img src="../assets/logo-extended.png" alt="X-orsystem" class="img-fluid px-3">
        </div>
        <div class="col-sm-9 my-auto text-right pt-5">
          <b-button :variant="variant.payment" class="mx-1">
            <span><i class="fad fa-sack-dollar"></i></span>
              <span class="pl-2"><strong>PAY</strong></span>
          </b-button>
          <b-button href="https://x-orsystem.com" :variant="variant.store" class="mx-1">
            <small>
              <span><i class="fad fa-store"></i></span>
              <span class="pl-2"><strong>REGRESAR A TIENDA</strong></span>
            </small>
          </b-button>
          <b-button href="https://soporte.x-orsystem.com" :variant="variant.support" class="mx-1 d-none">
            <span><i class="fad fa-ticket-alt"></i></span>
            <span class="pl-2"><strong>SOPORTE</strong></span>
          </b-button>
          <b-button :variant="variant.pc" class="mx-1 d-none">
            <small><strong>CONFIGURADOR DE PC</strong></small>
          </b-button>
        </div>
      </div>
      <hr>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      variant: ''
    }
  },
  props: [ 'application' ],
  mounted() {
    this.variant = {
      payment: (this.application == 'payment') ? 'primary' : 'white',
      store: (this.application == 'store') ? 'primary' : 'white',
      support: (this.application == 'support') ? 'primary' : 'white',
      pc: (this.application == 'pc') ? 'primary' : 'white'
    }
  },
}
</script>